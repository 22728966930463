<template>
  <section id="dashboard-analytics">
    <div class="home-dashboard my-2">
      <b-row>
        <b-col md="3" class="column-one">
          <b-card class="p-0 shadow-none employee-datalist">
            <div class="board-header">
              <h5>Track</h5>
              <!-- {{ okrs }} -->
            </div>
            <div class="body-content p-1">
              <ul class="m-0 p-0" >
                <router-link :to="`list/my-okrs`">
                <span v-if="okrs.length > 0">
                <li v-for="okr in okrs" v-if="okr"  :key="okr.hashid" class="list-unstyled pb-75 pt-25">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="okr-info profile-user-info">
                      <h6 class="mb-0">{{ okr.quarter }} {{ okr.year }} OKR</h6>
                      <small>{{ okr.user.name }}</small>
                    </div>
                    <b-badge 
                    :variant="okr.is_published == 0 ? 'light-info ': 'light-primary'"
                    >{{ okr.is_published == 0 ? "Submitted" : "Approved" }}</b-badge>
                  </div>
                </li>
              </span>
                <li  v-if="okrs.length > 0" class="list-unstyled"><hr class="my-25" /></li>
              </router-link>
        <span v-if="data.review_things"  >
        <li class="list-unstyled pb-75 pt-25" 
          v-if="data.review_things['Leave Application'] !== undefined && data.review_things['Leave Application'].length" 
          v-for="application in data.review_things['Leave Application']"  
          >
          <router-link
         :to="`/view/leave-application/${application.hashid}`"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="okr-info profile-user-info">
            <h6 class="mb-0">Leave Application</h6>
            <small>{{ application.message }}</small>
          </div>
          <b-badge 
          :variant="application.status === 0 ? 'light-info' : application.status === 1 ? 'light-primary' : 'light-danger'"
          > {{ 
    application.status === 0 
      ? "Submitted" 
      : application.status === 1 
        ? "Approved" 
        : "Rejected" 
  }}</b-badge>
        </div>
      </router-link>
      </li>
      <li class="list-unstyled pb-75 pt-25" 
          v-if="data.review_things['Regularize Application']!== undefined && data.review_things['Regularize Application'].length" 
          v-for="application in data.review_things['Regularize Application']">
          <router-link
         :to="`/view/regularize-application/${application.hashid}`"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="okr-info profile-user-info">
            <h6 class="mb-0">Regularize Application</h6>
            <small>{{ application.message }}</small>
          </div>
          <b-badge 
          :variant="application.status === 0 ? 'light-info' : application.status === 1 ? 'light-primary' : 'light-danger'"
          > {{ 
              application.status === 0 
                ? "Submitted" 
                : application.status === 1 
                  ? "Approved" 
                  : "Rejected" 
  }}</b-badge>
        </div>
      </router-link>
      </li>
      <li class="list-unstyled pb-75 pt-25" 
          v-if="data.review_things['Withdraw Application']!== undefined && data.review_things['Withdraw Application'].length" 
          v-for="application in data.review_things['Withdraw Application']">
          <router-link
         :to="`/view/leave-application/${application.hashid}`"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="okr-info profile-user-info">
            <h6 class="mb-0">Withdraw Application</h6>
            <small>{{ application.message }}</small>
          </div>
          <b-badge 
          :variant="application.status === 0 ? 'light-info' : application.status === 1 ? 'light-primary' : 'light-danger'"
          > {{ 
              application.status === 0 
                ? "Submitted" 
                : application.status === 1 
                  ? "Approved" 
                  : "Rejected" 
  }}</b-badge>
        </div>
      </router-link>
      </li>
      </span>
              </ul>
              <!-- Empty Data -->
              <!-- <div class="empty-data text-center my-4">
                <b-img :src="require('@/assets/images/icons/dashboard/office-management.svg')"alt="avatar"> </b-img>
                <h3 class="font-weight-bold">Nothing To Track</h3>
              </div> -->
              <!-- End Empty Data -->
            </div>
          </b-card>
          <b-card class="p-0 shadow-none leave-balance">
            <div class="board-header">
              <h5>Leave Balance</h5>
            </div>
            <div class="body-content p-1">
              <ul class="m-0 p-0">
                <li class="list-unstyled py-50" v-for="(leave, leave_type) in data.leave_balances"
              :key="leave_type"
              v-if="leave_display.includes(leave_type)">
                  <div class="d-flex align-items-center leave-list">
                    <span class="circle-line">{{ leave.actual_balance }}</span>
                    <h6 class="mb-0">{{leave_type}}</h6>
                  </div>
                </li>
              </ul>
              <div class="empty-data text-center my-3"  v-if="!data.leave_balances || data.leave_balances.length == 0" >
                <p class="m-auto py-2 d-flex align-items-center justify-content-center">No Data to display</p>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="column-two">
          <b-card class="p-0 shadow-none">
            <div class="p-1 user-attend">
              <div class="d-flex align-items-center mt-25">
                <span>{{ currentDay }}</span>
                <span class="vertical-line">|</span>
                <span>{{userData.shift_details['name']}}</span>
              </div>
              <h4 class="my-50">{{ formattedDate }}</h4>
              <div class="d-flex align-items-center justify-content-between mt-3 pt-25">
                <div class="d-flex align-items-start flex-column">
                <h6 class="timer mb-0">{{ time.hour + " : " + time.minute}}</h6>
                <b-button 
                  class="swipe-btn justify-content-start p-0" 
                  v-b-modal.recent-swipes
                  @click="getRecentSwipes"
                >
                <span class="recent-swipe">Recent <span>Swipes</span></span>
                </b-button>
              </div>
                <b-button 
                    class="logout-btn" 
                    v-if="userData.last_swipe_type === 'In'" 
                    @click="swipe('Out')">
                    <span class="align-middle">Check Out</span>
                  </b-button>

                  <b-button 
                    class="logout-btn" 
                    v-else 
                    @click="swipe('In')">
                    <span class="align-middle">Check In</span>
                  </b-button>

              </div>
            </div>
          </b-card>
          <b-card class="p-0 shadow-none team-leave upcoming-leave">
            <div class="board-header">
              <h5>Upcoming Teammates Leaves</h5>
            </div>
            <div class="body-content p-1">
              <ul class="m-0 p-0" v-if="teamUpcomingLeaves">
                <li class="list-unstyled pb-75 pt-25" v-for="la in teamUpcomingLeaves">
                  <div class="d-flex align-items-center">
                    <span class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                      <span class="b-avatar-img">
                        <b-img :src="la.user.avatar" alt="avatar"> </b-img>
                      </span>
                    </span>
                    <div class="profile-user-info mt-25">
                      <h6 class="mb-0">{{ la.user.name }}</h6>
                      <small>
                      <span>{{ getFromDate(la.from_date) }}</span>
                      <span v-if="la.to_date && la.from_date !=la.to_date"> to <span>{{ getFromDate(la.to_date) }}</span></span>
                    </small>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- Empty Data -->
              <div class="empty-data text-center my-3"  v-if="!teamUpcomingLeaves || teamUpcomingLeaves.length == 0" >
                <h3 class="py-1">It’s A Full House!</h3>
                <b-img :src="require('@/assets/images/icons/dashboard/team-mates.svg')" alt="avatar"> </b-img>
                <p class="m-auto py-2 w-75">None of your teammates have any leave on upcoming days!</p>
              </div>
              <!-- End Empty Data -->
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="column-three">
          <b-card class="p-0 shadow-none who-in">
            <div class="board-header">
              <h5>Who Is In?</h5>
            </div>
            <!-- {{data.teammates}} -->
            <div class="body-content p-1 position-relative">
              <ul class="m-0 p-0" v-if="data.teammates != undefined">
                <li class="list-unstyled pb-75 pt-25" v-for="teammate in data.teammates" >
                  <div class="d-flex align-items-center">
                    <span class="b-avatar mr-50 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                      <span class="b-avatar-img">
                        <b-img :src="teammate.avatar"  alt="avatar"> </b-img>
                      </span>
                    </span>
                    <div class="profile-user-info">
                      <h6 class="mb-0">{{teammate.name}}</h6>
                      <small>{{teammate.division}}</small>
                    </div>
                  </div>
                </li>
              </ul>
            <div class="empty-data text-center my-4 d-flex align-items-center flex-column justify-content-center" v-if="!data.teammates || data.teammates.length == 0 ">
              <b-img :src="require('@/assets/images/moods/cute.svg')" alt="avatar" width="65px" height="65px" class="mb-50"> </b-img>
              <p class="w-100 text-center mb-25">Congratulations!</p>
              <p class="w-100 text-center">You’re the first one in today!</p>
            </div>
            </div>
          </b-card>
          <b-card class="p-0 shadow-none holiday-view">
            <div class="board-header">
              <h5>Upcoming Holidays</h5>
            </div>
            <div class="body-content p-1 holiday-list">
              <ul class="m-0 p-0">
                <li  class="list-unstyled pb-75 pt-25" v-for="leave in data.recent_leaves" >
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="profile-user-info">
                      <h6 class="mb-0">{{ leave.holiday_name }}</h6>
                      <small>{{ leave.holiday_type }}</small>
                    </div>
                    <div class="date-blog d-flex flex-column align-items-center justify-content-center">
                      <h6 class="mb-0 date">{{ leave.date | getDate }}</h6>
                      <h5 class="mb-0 month">{{ leave.date | getMonth }}</h5>
                    </div>
                  </div>
                </li>
              </ul>
               <!-- Empty Data -->
               <div class="empty-data text-center my-3" v-if="!data.recent_leaves || data.recent_leaves.length == 0">
                <h3 class="py-1">Uh oh!</h3>
                <b-img :src="require('@/assets/images/icons/dashboard/no-holidays.svg')"alt="avatar"> </b-img>
                <p class="m-auto py-2">No upcoming holidays this in the next 30 days!</p>
              </div>
              <!-- End Empty Data -->
            </div>
          </b-card>
        </b-col>
        <b-col md="3" class="column-four">
          <b-card class="p-0 shadow-none team-leave">
            <div class="board-header">
              <h5>Teammates on leave</h5>
            </div>
            <div class="body-content p-1">
              <ul class="m-0 p-0" v-if="data.team_on_leave" >
                <li class="list-unstyled pb-75 pt-25" v-for="la in data.team_on_leave">
                  <div class="d-flex align-items-center">
                    <span class="b-avatar mr-75 badge-secondary rounded-circle" style="width: 40px; height: 40px; background: transparent;">
                      <span class="b-avatar-img">
                        <b-img :src="la.user.avatar"  alt="avatar"> </b-img>
                      </span>
                    </span>
                    <div class="profile-user-info mt-25">
                      <h6 class="mb-0">{{la.user.name}}</h6>
                      <small>
                      <span>{{ getFromDate(la.from_date) }}</span>
                      <span v-if="la.to_date && la.from_date !=la.to_date"> to <span>{{ getFromDate(la.to_date) }}</span></span>
                    </small>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- Empty Data -->
              <div class="empty-data text-center my-3" v-if="!data.team_on_leave || data.team_on_leave.length == 0" >
                <h3 class="py-1">It’s A Full House!</h3>
                <b-img :src="require('@/assets/images/icons/dashboard/team-mates.svg')" alt="avatar"> </b-img>
                <p class="m-auto py-2">No one on your team is on leave Today!</p>
              </div>
              <!-- End Empty Data -->
            </div>
          </b-card>
          <b-card class="p-0 shadow-none employee-mood">
            <div class="board-header">
              <h5>Mood - Last 30 Days</h5>
            </div>
            <div class="body-content p-1">
              <ul class="m-0 p-0">
                <li v-for="(count, mood) in mood.data" :key="mood" class="list-unstyled py-50">
                  <div class="d-flex align-items-center">
                    <span class="b-avatar mr-75 rounded-circle" style="width: 35px; height: 35px;">
                      <span class="b-avatar-img">
                        <b-img :src="getMoodImage(mood)" alt="avatar"></b-img>
                      </span>
                    </span>
                    <div class="profile-user-info mt-25">
                      <h6 class="mb-0">{{ mood }}</h6>
                      <small class="d-flex align-items-center mt-25">
                        {{ getMoodPercentage(count) }}%
                        <span class="ml-50">
                          <b-progress :value="getMoodPercentage(count)" max="100" class="flat-progress"></b-progress>
                        </span>
                      </small>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- Empty Data -->
              <div class="empty-data text-center my-3 d-flex flex-column align-items-center justify-content-center" v-if="!mood.data || mood.data.length == 0" >
                  <b-img :src="require('@/assets/images/icons/dashboard/mood-emptydata.svg')" alt="avatar"> </b-img>
                  <p class="m-auto py-2">Empty is the Mood</p>
                </div>
                <!-- End Empty Data -->
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>


    <!-- Old Code-->
    <b-row class="match-height d-none">
      <b-col lg="8" md="6">
        <!-- <analytics-congratulation :data="data.congratulations" /> -->
        <b-card overlay class="dashbord-img">
          <div class="main-card-1">
            <div class="cont-are">
              <br />
              <h2 style="color: black">
                <b>Hello {{ userData.name }}</b>
              </h2>
              <p style="color: black">Its good to see you again.</p>
              <hr
                style="
                  width: 50%;
                  text-align: left;
                  margin-left: 0;
                  margin-top: -8px;
                  border-top: 3px solid;
                  color: black;
                "
              />
              <h3 style="color: black"><b>Log Hours</b></h3>
              <label class="switch" style="font-size: 1rem">
                <input
                  type="checkbox"
                  id="swipe"
                  :checked="userData.last_swipe_type === 'In'"
                  @change="swipe($event)"
                />
                <span class="slider round"></span>
              </label>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                style="
                  margin-left: 0;
                  display: block;
                  padding: 0;
                  margin-top: 10px;
                "
                v-b-modal.recent-swipes
                @click="getRecentSwipes"
              >
                <h3 class="text-primary">Recent Swipes</h3>
              </b-button>
              <br />
            </div>
            <div class="clock-are" style="color: black">
              <div class="circle">
                <h1
                  style="
                    font-size: 50px;
                    text-align: center;
                    margin-top: 33%;
                    color: black;
                  "
                >
                  {{ time.hour + ":" + time.minute }}
                </h1>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="4" md="6">
        <!-- <analytics-congratulation :data="data.congratulations" /> -->
        <b-card>
          <b-card-title
            ><feather-icon icon="StarIcon" style="margin-top: -5px" /> Upcoming
            Holidays</b-card-title
          >
          <b-row class="match-height mt-1" v-for="leave in data.recent_leaves">
            <b-col lg="3" md="6">
              <p
                style="font-size: 20px; text-align: center"
                class="text-primary"
              >
                <b> {{ leave.date | getDate }}</b>
              </p>
              <p
                style="font-size: 20px; text-align: center; margin-top: -10px"
                class="text-primary"
              >
                <b> {{ leave.date | getMonth }}</b>
              </p>
            </b-col>
            <b-col lg="9" md="6">
              <p style="font-size: 20px">
                <b> {{ leave.holiday_name }}</b>
              </p>
              <p style="font-size: 15px; margin-top: -10px">
                {{ leave.holiday_type }}
              </p>
            </b-col>
          </b-row>
          <b-row
            class="match-height mt-1"
            v-if="!data.recent_leaves || data.recent_leaves.length == 0"
          >
            <b-img
              :src="
                require('@/assets/images/profile/user-uploads/no_result4.png')
              "
              class="img-responsive"
              style="width: 70%; margin-left: 15%"
              fluid
              alt="No Matching Data"
            />
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="8" md="6" class="leader-board-left" v-if="topThree.length">
        <b-card>
          <b-row class="justify-content-center my-1"
            ><h2 class="leader-board-title">FRONT-RUNNERS</h2></b-row
          >
          <b-row class="py-1d-flex justify-content-center top-winners">
            <b-col sm="12" md="4" v-if="topThree[1]">
              <div class="card border border-secondary second-place my-sm-3">
                <div class="leader-item second">
                  <b-link
                    :to="{
                      name: 'karma-history',
                      params: {
                        id: topThree[1].requested_for.hashid,
                        karma_program_id: topThree[1].karma_program_id,
                      },
                    }"
                    class="font-weight-bold text-nowrap text-center"
                  >
                    <div class="user-image">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Employee"
                        :src="topThree[1].requested_for.avatar"
                        size="4rem"
                        class="rounded-circle"
                      />
                    </div>
                    <div class="leader-trophy">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Winners"
                        :src="
                          require('@/assets/images/insider/second-place.png')
                        "
                        size="3rem"
                        class="rounded-circle"
                      />
                    </div>
                    <div class="user-content p-1">
                      <div class="name">
                        <h5 class="mb-0">
                          {{ topThree[1].requested_for.name }}
                        </h5>
                        <span
                          class="text-secondary small"
                          v-b-tooltip.hover.v-dark
                          v-b-tooltip.hover.bottom
                          :title="topThree[1].requested_for.designation"
                        >
                          {{
                            topThree[1].requested_for.designation
                              | str_limit(15)
                          }}
                        </span>
                      </div>
                      <div class="points">
                        <span
                          >{{ topThree[1].highScore }}
                          <span class="small">Pts</span></span
                        >
                      </div>
                    </div>
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4" class="mobile-card" v-if="topThree[0]">
              <div class="card border border-secondary first-place my-sm-3">
                <div class="leader-item one">
                  <b-link
                    :to="{
                      name: 'karma-history',
                      params: {
                        id: topThree[0].requested_for.hashid,
                        karma_program_id: topThree[0].karma_program_id,
                      },
                    }"
                    class="font-weight-bold text-nowrap text-center"
                  >
                    <div class="user-image">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Employee"
                        :src="topThree[0].requested_for.avatar"
                        size="5rem"
                        class="rounded-circle"
                      />
                    </div>
                    <div class="leader-trophy">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Winners"
                        :src="
                          require('@/assets/images/insider/first-place.png')
                        "
                        size="4rem"
                      />
                    </div>
                    <div class="user-content p-1">
                      <div class="name">
                        <h5 class="mb-0">
                          {{ topThree[0].requested_for.name }}
                        </h5>
                        <span
                          class="text-secondary small"
                          v-b-tooltip.hover.v-default
                          v-b-tooltip.hover.bottom
                          :title="topThree[0].requested_for.designation"
                        >
                          {{
                            topThree[0].requested_for.designation
                              | str_limit(15)
                          }}
                        </span>
                      </div>
                      <div class="points">
                        <span
                          >{{ topThree[0].highScore }}
                          <span class="small">Pts</span></span
                        >
                      </div>
                    </div>
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4" v-if="topThree[2]">
              <div class="card border border-secondary third-place my-sm-3">
                <div class="leader-item three">
                  <b-link
                    :to="{
                      name: 'karma-history',
                      params: { id: topThree[2].requested_for.hashid },
                    }"
                    class="font-weight-bold text-nowrap text-center"
                  >
                    <div class="user-image">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Employee"
                        :src="topThree[2].requested_for.avatar"
                        size="4rem"
                      />
                    </div>
                    <div class="leader-trophy">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Winners"
                        :src="
                          require('@/assets/images/insider/third-place.png')
                        "
                        size="3rem"
                      />
                    </div>
                    <div class="user-content p-1">
                      <div class="name">
                        <h5 class="mb-0">
                          {{ topThree[2].requested_for.name }}
                        </h5>
                        <span
                          class="text-secondary small"
                          v-b-tooltip.hover.v-default
                          v-b-tooltip.hover.bottom
                          :title="topThree[2].requested_for.designation"
                        >
                          {{
                            topThree[2].requested_for.designation
                              | str_limit(15)
                          }}
                        </span>
                      </div>
                      <div class="points">
                        <span
                          >{{ topThree[2].highScore }}
                          <span class="small">Pts</span></span
                        >
                      </div>
                    </div>
                  </b-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
        v-if="team_attendace_status && team_attendace_status.not_in_yet.length"
      >
        <b-card>
          <b-card-title
            ><feather-icon icon="UsersIcon" style="margin-top: -5px" />
            Teammates Not In Yet</b-card-title
          >
          <b-row
            v-for="(member, k) in team_attendace_status.not_in_yet"
            v-if="k < 4"
          >
            <b-col lg="9" md="6">
              <p style="font-size: 20px" class="text-primary mt-1">
                <b> {{ member.name }}</b>
              </p>
            </b-col>
            <b-col lg="3" md="6">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                :title="member.name"
                :src="member.avatar"
              />
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            :to="{ name: 'attendance-info' }"
            v-if="team_attendace_status.not_in_yet.length > 4"
          >
            See All
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="recent-swipes"
      hide-footer
      size="lg"
      modal-class=""
      centered
      scrollable
      title="Recent Swipes"
      header-class="sticky-top"
    >
      <b-list-group v-if="recentSwipes && recentSwipes.length">
        <b-list-group-item
          class="d-flex align-items-center"
          v-for="swipe in recentSwipes"
          :key="swipe.hashid"
        >
          <b class="pr-50">Date : </b> {{ swipe.date | formatDate }}, <b class="px-50"> Time : </b>
          {{ swipe.time | formatTime }}, <b class="px-50"> Swipe type : </b>
          {{ swipe.type }}
          <b-img
            v-if="swipe.user_feeling != 6"
            v-bind="{ width: 40, height: 40, class: 'mr-0 ml-auto' }"
            fluid
            :src="mood_options[swipe.user_feeling]"
            alt="mood"
          />
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="recentSwipes && recentSwipes.length == 0">
        <div class="item error text-center my-2 empty-data">
              <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
              <h4> OH NO!</h4>
              <p class="d-block mb-1">No Records Found!</p>
            </div>
      </b-card-text>
      <b-card-text v-else="recentSwipes == null">
        <div class="d-flex align-items-center">
          <strong>Loading...</strong>
          <b-spinner class="ml-auto" variant="primary" />
        </div>
      </b-card-text>
    </b-modal>
    <!-- <b-modal
      id="modal-1"
      title=""
      size="lg"
      @show="resetModal"
      @hidden="cancelModal"
      @ok="handleOk"
      :hide-header="true"
      :hide-footer="true"
      centered
    >
      <div v-if="'No' in userData.previous_day_attendance_status">
        <p class="my-1 text-center">
          Regularization Required for last working day -
          {{ userData.previous_day_attendance_status.No | formatDate }}, Please
          Apply for Regularization for last working day
        </p>
      </div>
      <form ref="form" @submit.stop.prevent="handleOk">
        <b-form-group label="" label-for="name-input" class="">
          <p class="h2 my-1 text-center text-dark">
            How are you feeling today?
          </p>
          <b-form-radio-group
            buttons
            class="mx-n1 my-2"
            size="sm"
            name="user_feeling"
            v-model="swipeData.user_feeling"
            button-variant="light"
          >
            <b-form-radio
              v-for="option in options"
              :value="option.value"
              :key="option.text"
              :class="`border-0 rounded-pill btn-light ml-1 m-0 mood-btn`"
            >
              <b-img fluid :src="option.img" alt="mood" class="" />
              {{ option.text }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox value="6" v-model="swipeData.user_feeling">
            Prefer not to say
          </b-form-checkbox>
        </b-form-group>
        <div class="text-center">
          <b-button
            type="submit"
            variant="outline-primary"
            v-if="swipeData.user_feeling"
          >
            Submit
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-2"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="checkout"
      :hide-header="true"
      :hide-footer="true"
      centered
    >
      <form ref="form" @submit.stop.prevent="checkout">
        <b-form-group label="" label-for="name-input" class="">
          <p class="h2 my-1 text-center text-dark">How you felt today?</p>
          <br />
          <b-form-radio-group
            buttons
            class="mx-n1 my-2 feeling-smiley"
            size="sm"
            name="user_feeling"
            v-model="swipeData.user_feeling"
            button-variant="light"
          >
            <b-form-radio
              v-for="option in options"
              :value="option.value"
              :key="option.text"
              :class="`border-0 rounded-pill btn-light ml-1 m-0 mood-btn`"
            >
              <b-img fluid :src="option.img" alt="mood" />
              {{ option.text }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            value="6"
            v-model="swipeData.user_feeling"
            class="d-flex align-items-center"
          >
            Prefer not to say
          </b-form-checkbox>
        </b-form-group>
        <div class="text-center">
          <b-button
            type="submit"
            variant="outline-primary"
            v-if="swipeData.user_feeling"
          >
            Submit
          </b-button>
        </div>
      </form>
    </b-modal> -->
    <b-row v-if="OKRData" class="d-none">
      <b-col md="12">
        <b-card class="mt-2">
          <b-card-title
            ><feather-icon icon="TargetIcon" style="margin-top: -5px" /> OKR
            Progress <small> Latest OKR</small></b-card-title
          >
          <b-row>
            <b-col md="12">
              <b-progress
                key="progress"
                variant="primary"
                class="'progress-bar-primary"
              >
                <b-progress-bar
                  v-for="(progress, key) in getProgress"
                  :value="progress.value"
                  :variant="progress.class"
                  :title="key"
                >
                  <strong>{{ progress.value }}%</strong>
                </b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height d-none">
      <b-col lg="4" md="6">
        <b-card style="height: 500px">
          <b-card-title
            ><feather-icon icon="RefreshCcwIcon" style="margin-top: -5px" />
            Attendance Analytics <small> last 3 months</small></b-card-title
          >
          <v-chart
            class="chart responsive"
            style="margin-top: -70px"
            :option="option"
          />
        </b-card>
      </b-col>
      <b-col lg="4" md="6">
        <b-row>
          <b-card class="h-100 w-100">
            <b-card-title
              ><feather-icon icon="InfoIcon" style="margin-top: -5px" /> Leave
              Balance</b-card-title
            >
            <b-row
              class="mt-1"
              v-for="(leave, leave_type) in data.leave_balances"
              :key="leave_type"
              v-if="leave_display.includes(leave_type)"
            >
              <b-col lg="2" md="6">
                <p
                  style="font-size: 20px; text-align: center"
                  class="text-primary"
                >
                  <b> {{ leave.actual_balance }}</b>
                </p>
              </b-col>
              <b-col lg="10" md="6">
                <p style="font-size: 20px">Days of {{ leave_type }} left.</p>
              </b-col>
            </b-row>
            <b-row
              class="match-height mt-1"
              v-if="!data.leave_balances || data.leave_balances.length == 0"
            >
              <b-img
                :src="
                  require('@/assets/images/profile/user-uploads/no_result4.png')
                "
                class="img-responsive"
                style="width: 70%; margin-left: 15%"
                fluid
                alt="No Matching Data"
              />
            </b-row>
          </b-card>
        </b-row>
        <b-row class="mt-2">
          <b-card class="w-100">
            <b-card-title
              ><feather-icon icon="EditIcon" style="margin-top: -5px" /> Things
              to Review</b-card-title
            >
            
        <b-row
              class="match-height"
              v-for="(review_data, review_type) in data.review_things"
              :key="review_type"
              v-if="review_data.length > 0">

              <b-col lg="4">
                
                <p style="font-size: 15px" class="text-primary">
                  <b>{{ review_data[0].withdraw_request != null ? "Withdraw Application" : review_type }}</b>
                </p>
                 
              </b-col>


              <b-col lg="6">
                <p style="font-size: 14px">
                  {{ review_data[0].message }}
                </p>
              </b-col>


              <b-col lg="2"> 

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                  :to="{
                    path:
                      '/view/' +
                      review_type.split(' ').join('-').toLowerCase() +
                      '/' +
                      review_data[0].hashid,
                  }"
                >
                  <feather-icon icon="EditIcon" />
               </b-button> 

              </b-col>

            </b-row>

            <b-row
              class="match-height mt-1"
              v-if="!data.review_things || data.review_things.length == 0"
            >
              <b-img
                :src="
                  require('@/assets/images/profile/user-uploads/no_result4.png')
                "
                class="img-responsive"
                style="width: 70%; margin-left: 15%"
                fluid
                alt="No Matching Data"
              />
            </b-row>
          </b-card>
        </b-row>
      </b-col>
      <b-col lg="4" md="6">
        <b-card>
          <b-card-title
            ><feather-icon icon="UsersIcon" style="margin-top: -5px" /> Team On
            Leave</b-card-title
          >
          <b-row v-for="la in data.team_on_leave">
            <b-col lg="9" md="6">
              <p style="font-size: 20px" class="text-primary">
                <b> {{ la.user.name }}</b>
              </p>
              <p style="font-size: 15px; margin-top: -10px">
                <b>
                  {{ la.leave.type }} | {{ getFromDate(la.from_date) }} |
                  {{ la.duration }} Days</b
                >
              </p>
            </b-col>
            <b-col lg="3" md="6">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                :title="la.user.name"
                size="lg"
                :src="la.user.avatar"
              />
            </b-col>
          </b-row>
          <b-row
            class="match-height mt-1"
            v-if="!data.team_on_leave || data.team_on_leave.length == 0"
          >
            <b-img
              :src="
                require('@/assets/images/profile/user-uploads/no_result4.png')
              "
              class="img-responsive mt-4"
              fluid
              alt="No Matching Data"
            />
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4" md="6">
        <b-card>
          <b-card-title
            ><feather-icon icon="UsersIcon" style="margin-top: -5px; margin-right: 5px;" />Upcoming Teammates Leaves</b-card-title
          >
          <b-row v-for="la in teamUpcomingLeaves">
            <b-col lg="9" md="6">
              <p style="font-size: 20px" class="text-primary">
                <b> {{ la.user.name }}</b>
              </p>
              <p style="font-size: 15px; margin-top: -10px">
                <b>
                  {{ la.leave.type }} | {{ getFromDate(la.from_date) }} 
                  <!-- {{ la.duration }} Days</b -->
                </b>
              </p>
            </b-col>
            <b-col lg="3" md="6">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                :title="la.user.name"
                size="lg"
                :src="la.user.avatar"
              />
            </b-col>
          </b-row>
          <b-row
            class="match-height mt-1"
            v-if="!teamUpcomingLeaves || teamUpcomingLeaves.length == 0"
          >
            <b-img
              :src="
                require('@/assets/images/profile/user-uploads/no_result4.png')
              "
              class="img-responsive mt-4"
              fluid
              alt="No Matching Data"
            />
          </b-row>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
        v-if="team_attendace_status && team_attendace_status.on_time.length"
      >
        <b-card class="overflow-hidden">
          <b-card-title
            ><feather-icon icon="UsersIcon" style="margin-top: -5px" />
            Teammates On Time</b-card-title
          >
          <b-row
            v-for="(member, k) in team_attendace_status.on_time"
            v-if="k < 4"
          >
            <b-col lg="9" md="6">
              <p style="font-size: 20px" class="text-primary mt-1">
                <b> {{ member.name }}</b>
              </p>
            </b-col>
            <b-col lg="3" md="6">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                :title="member.name"
                :src="member.avatar"
              />
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            :to="{ name: 'attendance-info' }"
            v-if="team_attendace_status.on_time.length > 4"
          >
            See All
          </b-button>
        </b-card>
      </b-col>
      <!-- <b-col
        lg="4"
        md="6"
        v-if="team_attendace_status && team_attendace_status.late_in.length"
      >
        <b-card>
          <b-card-title
            ><feather-icon icon="UsersIcon" style="margin-top: -5px" />
            Teammates Late In</b-card-title
          >
          <b-row
            v-for="(member, k) in team_attendace_status.late_in"
            v-if="k < 4"
          >
            <b-col lg="9" md="6">
              <p style="font-size: 20px" class="text-primary mt-1">
                <b> {{ member.name }}</b>
              </p>
            </b-col>
            <b-col lg="3" md="6">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                :title="member.name"
                :src="member.avatar"
              />
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            :to="{ name: 'attendance-info' }"
            v-if="team_attendace_status.late_in.length > 4"
          >
            See All
          </b-button>
        </b-card>
      </b-col> -->
    </b-row>
    <b-row class="d-none">
      <b-col lg="6">
        <analytics-support-tracker
          :data="data.supportTracker"
          v-if="data.supportTracker && userData.role == 'Zircly Admin'"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="data.subscribersGained && userData.role == 'Zircly Admin'"
          icon="UsersIcon"
          :statistic="
            kFormatter(data.subscribersGained.analyticsData.subscribers)
          "
          statistic-title="Total Employees"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied && userData.role == 'Zircly Admin'"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="Active Candidates"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row>

    <b-row
      class="match-height d-none"
      v-if="data.moods && userData.role == 'Zircly Admin'"
    >
      <b-col lg="12" md="12">
        <mood-chart :moods="data.moods" />
      </b-col>
    </b-row>

    <!-- <b-row class="match-height" v-if="userData.role == 'Zircly Admin'">
      <b-col lg="12">
        <analytics-timeline :data="data.timeline" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BImg,
} from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";
import MoodChart from "./MoodChart.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { D } from "caniuse-lite/data/agents";
import $ from "jquery";
import { bus } from "@/main.js";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

Vue.use(VueCookies);
export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    VChart,
    BProgress,
    BProgressBar,
    BImg,
    MoodChart,
  },
  provide: {
    [THEME_KEY]: "lite",
  },
  data() {
    return {
      mood: {},
      okrs: [],
      currentDate: new Date(),
      data: {
        moods: [],
      },
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      time: { hour: 0, minute: 0 ,second:0},
      last_swipe_type: "Out",
      refreshIntervalId: null,
      teamUpcomingLeaves:null,
      OKRData: null,
      team_attendace_status: null,
      statusList: [
        {
          label: "Not Started Yet",
          code: 0,
        },
        {
          label: "In Progress",
          code: 1,
        },
        {
          label: "Completed",
          code: 2,
        },
        {
          label: "On Hold",
          code: 3,
        },
        {
          label: "Cancelled",
          code: 4,
        },
      ],
      recentSwipes: null,
      options: [
        {
          text: "Energetic",
          value: "5",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/cute.svg"),
        },
        {
          text: "Focused",
          value: "4",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/shy.svg"),
        },
        {
          text: "Neutral",
          value: "3",
          color: "btn-outline-primary",
          icon: "PauseIcon",
          img: require("@/assets/images/moods/neutral.svg"),
        },
        {
          text: "Stressed",
          value: "2",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/thinking.svg"),
        },
        {
          text: "Frustrated",
          value: "1",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/angry.svg"),
        },
      ],
      swipeData: {
        type: "",
        user_feeling: 6,
      },
      leave_display: ["Casual Leave", "Restricted Holiday", "Compensatory Off"],
      userData: this.$cookies.get("userData"),
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: "center",
        },
        series: [
          {
            name: "My Regularize Rate",
            type: "pie",
            radius: ["30%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: "Present" },
              { value: 0, name: "Regularised" },
              { value: 0, name: "Absent" },
              { value: 0, name: "Holiday" },
              { value: 0, name: "RestDay" },
            ],
          },
        ],
      },
      teammates:null,
      mood_options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
      moodImages: {
        Frustrated: require("@/assets/images/moods/angry.svg"),
        Stressed: require("@/assets/images/moods/thinking.svg"),
        Neutral: require("@/assets/images/moods/neutral.svg"),
        Focused: require("@/assets/images/moods/shy.svg"),
        Energetic: require("@/assets/images/moods/cute.svg")
      },
      topThree: [],
    };
  },
  // watch: {
  //   last_swipe_type: function (val) {
  //     if (val == "In") {
  //       this.refreshIntervalId = setInterval(() => {
  //         console.log(this.time);
  //         if (this.time.minute == 59) {
  //           this.time.minute = "00";
  //           this.time.hour++;
  //           if (this.time.hour < 10) {
  //             this.time.hour = "0" + this.time.hour;
  //           }
  //         } else {
  //           this.time.minute++;
  //           if (this.time.minute < 10) {
  //             this.time.minute = "0" + this.time.minute;
  //           }
  //         }
  //         if (this.time.hour == 24) {
  //           this.time.hour = "00";
  //         }
  //         console.log(this.time);
  //       }, 60000);
  //     } else {
  //       if (this.refreshIntervalId != null) {
  //         clearInterval(this.refreshIntervalId);
  //       }
  //     }
  //   },
  // },

  watch: {
    last_swipe_type: function (val) {
        if (val == "In") {
            this.startInterval(); // Start the interval when checking in
        } else {
            if (this.refreshIntervalId != null) {
                clearInterval(this.refreshIntervalId);
                this.refreshIntervalId = null; // Reset interval id
            }
        }
    },

},

  created() {

    // update the time every second

    if (!this.userData) {
      this.$router.push({ name: "auth-login" });
    }

    // data
    this.data = {
      congratulations: {
        name: "",
      },
      subscribersGained: {
        series: [
          {
            name: "Total Employees",
            data: [],
          },
        ],
        analyticsData: {
          subscribers: 0,
        },
      },
      ordersRecevied: {
        series: [
          {
            name: "Total Active Candidate",
            data: [],
          },
        ],
        analyticsData: {
          orders: 0,
        },
      },
      supportTracker: {
        title: "Candidates Tracker",
        totalTicket: 0,
        newTicket: 0,
        openTicket: 0,
        responseTime: 0,
        supportTrackerRadialBar: {
          series: [],
        },
      },
      timeline: {},
    };
    this.$http.get("/dashboard").then((response) => {
      this.data.congratulations.name = response.data.data.latest_onbord;
      this.data.recent_leaves = response.data.data.recent_leaves;
      this.data.leave_balances = response.data.data.leave_balances;
      this.data.review_things = response.data.data.review_things;
      this.data.team_on_leave = response.data.data.team_on_leave;
      this.time = response.data.data.worked_hours;
      this.option.series[0].data = response.data.data.attendance_data;
      this.OKRData = response.data.data.OKRData;
      this.last_swipe_type = response.data.data.last_swipe_type;
      this.team_attendace_status = response.data.data.team_attendace_status;
      this.data.subscribersGained.analyticsData.subscribers =
        response.data.data.active_employees_count;
      this.data.ordersRecevied.analyticsData.orders =
        response.data.data.active_candidates_count;
      this.data.supportTracker.totalTicket =
        response.data.data.candidates_tracking.candidates;
      this.data.supportTracker.newTicket =
        response.data.data.candidates_tracking.open_candidates;
      this.data.supportTracker.openTicket =
        response.data.data.candidates_tracking.selected_candidates;
      this.data.supportTracker.responseTime =
        response.data.data.candidates_tracking.rejected_candidates;
      this.data.supportTracker.supportTrackerRadialBar.series.push(
        response.data.data.candidates_tracking.onboard_success_percentage
      );
      this.data.timeline = response.data.data.lateste_interview_reviews;
      this.data.moods = response.data.data.mood;
      this.mood = response.data.data.mood;
      this.teamUpcomingLeaves = response.data.data.teamUpcomingLeaves;
      this.data.teammates = response.data.data.who_is_in;
      this.okrs = response.data.data.okrs ? [response.data.data.okrs] : [];

    });
    
    this.getTopThree();

    bus.$on('user-swipe',(swiped)=>{
      if(swiped){
        this.userData = this.$cookies.get("userData")
      }
    })
  },

  methods: {
    kFormatter,
    getMoodPercentage(count) {
      const totalMoods = Object.values(this.mood.data).reduce((acc, value) => acc + value, 0);
      if (totalMoods === 0) {
        return 0;
      }
      return ((count / totalMoods) * 100).toFixed(2);
    },
    getMoodImage(mood) {
      return this.moodImages[mood] || require('@/assets/images/avatars/1.png');
    },
    getDay(date) {
      return new Date(date).getDate();
    },
    getMonth(date) {
      return this.month[new Date(date).getMonth()];
    },
    getFromDate(date) {
      return (
        this.month[new Date(date).getMonth()] + " " + new Date(date).getDate()
      );
    },
    getStatusLabel(status) {
      this.statusList.forEach(function (obj, key) {});
      var label = "Not Started Yet";
      for (var key in this.statusList) {
        if (status == this.statusList[key].code) {
          label = this.statusList[key].label;
        }
      }
      return label;
    },
    getRecentSwipes() {
      axios.get("/swipes").then((response) => {
        if (response.data.success) {
          this.recentSwipes = response.data.data;
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    checkin() {
      this.swipeData.type = "In";
      this.swipeData.browser_agent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      this.swipeData.device_type = deviceType;
      axios.post("/swipe/add", this.swipeData).then((response) => {
        this.userData.last_swipe_type = "In";
        this.$cookies.set(
          "userData",
          this.userData,
          60 * process.env.VUE_APP_LOGIN_TIMEOUT
        );
        this.$nextTick(() => {
          this.$bvModal.hide("modal-1");
        });

        this.$toast.success(response.data.message);
        this.startInterval()
      });
    },
    checkout() {
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';

      axios
        .post("/swipe/add", {
          type: "Out",
          user_feeling: this.swipeData.user_feeling,
          browser_agent: navigator.userAgent,
          device_type: deviceType,
        })
        .then((response) => {
          this.userData.last_swipe_type = "Out";
          this.$cookies.set(
            "userData",
            this.userData,
            60 * process.env.VUE_APP_LOGIN_TIMEOUT
          );
          this.$nextTick(() => {
            this.$bvModal.hide("modal-2");
          });
          this.$toast.success(response.data.message);
        });

        if (this.refreshIntervalId != null) {
            clearInterval(this.refreshIntervalId);
            this.refreshIntervalId = null;
        }
    },

    startInterval(){
      this.refreshIntervalId = setInterval(() => {
            if (this.time.second == 59) {
                this.time.second = "00";
                if (this.time.minute == 59) {
                    this.time.minute = "00";
                    this.time.hour++;
                    if (this.time.hour < 10) {
                        this.time.hour = "0" + this.time.hour;
                    }
                } else {
                    this.time.minute++;
                    if (this.time.minute < 10) {
                        this.time.minute = "0" + this.time.minute;
                    }
                }
                if (this.time.hour == 24) {
                    this.time.hour = "00";
                }
            } else {
                this.time.second++;
                if (this.time.second < 10) {
                    this.time.second = "0" + this.time.second;
                }
            }
        }, 1000);
    },
    resetModal() {
      this.swipeData.type = "";
      this.swipeData.user_feeling = 6;
    },
    cancelModal() {
      if (this.userData.last_swipe_type == "Out") {
        $("#swipe").prop("checked", false);
      }
      this.swipeData.type = "";
      this.swipeData.user_feeling = 6;
    },
    // handleOk(bvModalEvent) {
    //   // Prevent modal from closing
    //   bvModalEvent.preventDefault();
    //   // Trigger submit handler
    //   this.checkin();
    // },
    swipe(event) {
    if (event === 'Out') {
      this.$bvModal.show("modal-2");
    } else {
      this.$bvModal.show("modal-1");
    }
    },

    getTopThree() {
      const self = this;

      let data = {};
      data.url = "current-karma-leaderboard/";
      data.params = {
        perPage: 7,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.topThree = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.isBusy = false;
        });
    },
  },
  computed: {
    formattedDate() {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return this.currentDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    },
    currentDay() {
      const options = { weekday: 'long' };
      return this.currentDate.toLocaleDateString('en-US', options);
    },
    getProgress() {
      var statusPercentanges = [];
      var progresses = {
        Completed: { value: 0, class: "success" },
        "In Progress": { value: 0, class: "warning" },
        Cancelled: { value: 0, class: "danger" },
        "On Hold": { value: 0, class: "info" },
        "Not Started Yet": { value: 0, class: "dark" },
      };
      var totalCount = this.OKRData.okr_details.length;
      for (var key in this.OKRData.okr_details) {
        var sk = this.getStatusLabel(this.OKRData.okr_details[key].status);
        if (statusPercentanges[sk]) {
          statusPercentanges[sk] += 1;
        } else {
          statusPercentanges[sk] = 1;
        }
      }
      for (var key in statusPercentanges) {
        progresses[key].value = (
          (statusPercentanges[key] / totalCount) *
          100
        ).toFixed(2);
      }
      return progresses;
    },
  },
  mounted() {
    axios.get("/user").then((response) => {
      const userData = response.data.data;
      this.$cookies.set(
        "userData",
        userData,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      );
    });

    if (
      "No" in this.userData.previous_day_attendance_status &&
      (!this.$cookies.get("lastday_status_shown") ||
        this.$cookies.get("lastday_status_shown") != 1)
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "You have not fulfilled min hours required for " +
              this.userData.previous_day_attendance_status.No,
            icon: "AlertTriangleIcon",
            variant: "warning",
            text: "Regularization Required for last working day, Please Apply for Regularization for last working day",
          },
        },
        {
          timeout: 6000,
        }
      );
      this.$cookies.set(
        "lastday_status_shown",
        1,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      );
    }
  },
};
</script>

<style>
hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}
.circle {
  height: 200px;
  width: 200px;
  border-radius: 25%;
  border: 7px solid;
}
.switch {
  position: relative;
  display: inline-block;
  width: 3.75em;
  height: 2.125em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.625em;
  width: 1.625em;
  left: 0.25em;
  bottom: 0.25em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #38b22d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #38b22d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.625em);
  -ms-transform: translateX(1.625em);
  transform: translateX(1.625em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2.125em;
}

.slider.round:before {
  border-radius: 50%;
}
.dashbord-img {
  background-image: url("../../assets/images/profile/user-uploads/dashboard-swipe-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.dashbord-img .card-img-overlay {
  position: relative;
  display: flex;
  padding: 20px;
}
.dashbord-img .card-img-overlay .main-card-1 {
  background: rgb(255 255 255 / 70%);
  padding: 20px 30px;
  border-radius: 0.428rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dashbord-img .card-img-overlay .clock-are {
  padding: 0 0 0 30px;
}
</style>
<style lang="scss">
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
// .mood-btn:hover {
//   scale: 1.3;
//   background: transparent;
//   opacity: 1;
// }
// .mood-btn {
//   scale: 0.9;
//   background: transparent;
//   opacity: 0.7;
//   transition: all 0.3s;
// }
// .mood-btn.active {
//   scale: 1.2;
//   background: transparent;
//   background-color: transparent !important;
//   opacity: 1;
// }

.swipe-btn {
    display: inline-block;
    width: 100px; 
    height: 20px;
    padding: 0 15px; 
    border-radius: 5px; 
    border: none;
    cursor: pointer; 
    text-align: center;
  }

</style>